/**
 * @desc 读取文件并返回其 base64 字符串
 * @param file
 * @returns {Promise<string>}
 */
export const file2Base64 = async file => {
  try {
    const reader = new FileReader();

    return await new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );

      reader.addEventListener('error', () => reject(), false);

      reader.readAsDataURL(file);
    });
  } catch (e) {
    return '';
  }
};
