<template>
  <!-- 图片裁切器对话框 -->
  <el-dialog
    class="image-cropper"
    :visible.sync="open"
    :title="title"
    :width="`${modalWidth}px`"
    append-to-body
    :close-on-click-modal="false"
    @close="handleDialogClose"
  >
    <!-- 图片裁切容器 -->
    <div class="cropper-container">
      <!-- 图片裁切核心 -->
      <div class="cropper-main">
        <div>
          <img
            ref="img"
            class="main-img"
            :src="imageBase64"
            alt="原图"
            @load="handleCropperImgLoad"
            @crop="handleCropperImgCrop"
          />
        </div>
      </div>

      <!-- 右侧预览容器和操作按钮容器 -->
      <div class="cropper-extras">
        <!-- 裁切后缩略图预览容器 -->
        <div class="extras-preview">
          <div class="preview-label">预览:</div>

          <img
            class="preview-thumbnail"
            :style="{ width: `${width}px` }"
            :src="croppedBase64"
            alt="缩略图"
          />
        </div>

        <!-- 操作按钮容器 -->
        <div class="extras-control">
          <div>操作:</div>

          <!-- 缩放按钮组 -->
          <div class="control-btn">
            <el-button plain size="mini" @click="handleCropperZoom(0.1)">
              放大
            </el-button>

            <el-button plain size="mini" @click="handleCropperZoom(-0.1)">
              缩小
            </el-button>
          </div>

          <!-- 旋转按钮组 -->
          <div class="control-btn">
            <el-button plain size="mini" @click="handleCropperRotate(45)">
              右转
            </el-button>

            <el-button plain size="mini" @click="handleCropperRotate(-45)">
              左转
            </el-button>
          </div>

          <!-- 翻转按钮组 -->
          <div class="control-btn">
            <el-button plain size="mini" @click="handleCropperFlip('x')">
              上下
            </el-button>

            <el-button plain size="mini" @click="handleCropperFlip('y')">
              左右
            </el-button>
          </div>

          <!-- 重置按钮 -->
          <div class="control-btn">
            <el-button plain size="mini" @click="handleCropperReset()">
              重置
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <div class="cropper-operate">
        <el-button size="small" @click="handleCancelClick"> 取消 </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="handleOkClick"
        >
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.min.css';
  import { file2Base64 } from '../../util/file';

  export default {
    name: 'image-cropper',
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 需要裁切的图片，base64 或者 file 对象
      image: {
        type: [File, String],
        required: true,
      },
      // 对话框标题
      title: {
        type: String,
        default: '图片裁切',
      },
      // 对话框宽度
      modalWidth: {
        type: Number,
        default: 640,
      },
      // 确定按钮加载中
      loading: {
        type: Boolean,
        default: false,
      },
      // 需要裁切的宽度
      width: {
        type: Number,
        default: 480,
      },
      // 需要裁切的比例
      ratio: {
        type: Number,
        default: 1,
      },
      // 裁切图片的质量
      quality: {
        type: Number,
        default: 1,
        validator: val => typeof val === 'number' && val >= 0 && val <= 1,
      },
    },
    data() {
      return {
        // 原始图片文件的 base64
        imageBase64: '',
        // cropper 对象
        cropper: null,
        // cropper 对象的配置选项
        config: {
          // 裁切比例
          aspectRatio: 1,
          // 双击切换 dragMode
          toggleDragModeOnDblclick: false,
          // 裁切容器的最小高度
          minContainerHeight: 350,
          // 裁切框上的提示虚线
          guides: false,
        },
        // 图片裁切后的 base64
        croppedBase64: '',
        // 图片裁切后的 ArrayBuffer
        croppedArrayBuffer: null,
      };
    },
    computed: {
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 裁切输出的图片宽高尺寸
      croppedImageSize() {
        return {
          width: this.width,
          height: this.width / this.ratio,
        };
      },
    },
    methods: {
      // 图片 load 事件
      handleCropperImgLoad() {
        // 如果 cropper 对象已经创建，则先销毁
        this.cropper && this.cropper.destroy();

        const imgEl = this.$refs.img;
        const cropper = new Cropper(imgEl, {
          ...this.config,
          aspectRatio: this.ratio,
        });

        // 在当前cropper对象上附加私有属性，用于记录当前的翻转状态，默认x为1，y为1
        cropper._flip = { x: 1, y: 1 };

        this.cropper = cropper;
      },
      // 图片 crop 事件(cropper 实现的)
      handleCropperImgCrop() {
        const { width, height } = this.croppedImageSize;
        // 裁切框中图片内容所在的 canvas 对象
        const croppedCanvas = this.cropper.getCroppedCanvas({
          width,
          height,
        });

        // 从裁切框 canvas 对象中获取裁切后图片的 base64
        this.croppedBase64 = croppedCanvas.toDataURL('image/png', this.quality);

        // 从裁切框 canvas 对象中获取裁切后图片的二进制流
        croppedCanvas.toBlob(
          blob => {
            const fileReader = new FileReader();

            fileReader.addEventListener('load', () => {
              this.croppedArrayBuffer = fileReader.result;
            });

            blob && fileReader.readAsArrayBuffer(blob);
          },
          'image/png',
          this.quality
        );
      },
      // 缩放按钮事件
      handleCropperZoom(ratio) {
        this.cropper.zoom(ratio);
      },
      // 旋转按钮事件
      handleCropperRotate(degree) {
        this.cropper.rotate(degree);
      },
      // 翻转按钮事件
      handleCropperFlip(orientation) {
        // cropper对象
        const cropper = this.cropper;

        // 沿着x轴翻转——即上下镜像
        if (orientation === 'x') {
          // 获取附加在当前cropper对象上的用于记录翻转状态的_flip属性
          const y = cropper._flip.y;

          cropper.scaleY(-y);
          // 重写scaleY的值为修改后的值
          cropper._flip.y = -y;
        }
        // 沿着y轴翻转——即左右镜像
        else if (orientation === 'y') {
          // 获取附加在当前cropper对象上的用于记录翻转状态的_flip属性
          const x = cropper._flip.x;

          cropper.scaleX(-x);
          // 重写scaleY的值为修改后的值
          cropper._flip.x = -x;
        }
      },
      // 重置按钮单击
      handleCropperReset() {
        this.cropper.reset();
      },
      // 取消按钮单击
      handleCancelClick() {
        this.open = false;
      },
      // 确定按钮单击
      handleOkClick() {
        this.$emit('on-ok', {
          base64: this.croppedBase64,
          arrayBuffer: this.croppedArrayBuffer,
        });
      },
      // 对话框关闭
      handleDialogClose() {
        this.$emit('on-close');
      },
    },
    watch: {
      async image(n) {
        this.imageBase64 = typeof n === 'string' ? n : await file2Base64(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .image-cropper {
    // 图片裁切容器
    .cropper-container {
      display: flex;
      overflow: hidden;

      // 图片裁切核心
      .cropper-main {
        flex-shrink: 1;

        // 原图片
        .main-img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      // 右侧预览容器和操作按钮容器
      .cropper-extras {
        width: 100px;
        flex-shrink: 0;
        margin-left: 20px;

        // 预览缩略图
        .extras-preview {
          // 预览修饰文本
          .preview-label {
            line-height: 20px;
            font-size: 14px;
          }

          // 缩略图
          .preview-thumbnail {
            max-width: 100% !important;
            margin-top: 10px;
          }
        }

        // 操作按钮容器
        .extras-control {
          margin-top: 25px;

          // 按钮组间距
          .control-btn {
            & + .control-btn {
              margin-top: 15px;
            }

            .el-button {
              min-width: auto;
              width: 45px;
              padding: 6px 10px;
            }
          }
        }
      }
    }
  }
</style>
